const tapForm = localStorage.getItem("tapForm")
  ? JSON.parse(localStorage.getItem("tapForm"))
  : null;

const state = {
	tabs: [],
	tapForm: tapForm,
};

const mutations = {
	SET_TABS(state, payload) {
		state.tabs = payload;
	},
	SET_TAP_FORM(state, payload) {
    state.tapForm = payload;
  },
};

const actions = {
	setTabs(context, payload) {
		context.commit("SET_TABS", payload);
	},
	setTapForm(context, payload) {
    if (payload) {
      localStorage.setItem("tapForm", JSON.stringify(payload));
    } else {
      localStorage.removeItem("tapForm");
    }

    context.commit("SET_TAP_FORM", payload);
  },
};

const getters = {
	getTabs(state) {
		return state.tabs;
	},
	getTapForm(state) {
    return state.tapForm;
  },
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
