const groupBy = localStorage.getItem("groupBy")
  ? JSON.parse(localStorage.getItem("groupBy"))
  : false;

const state = {
  dataTableOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 25,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: ["node.reportName"],
    sortDesc: [false],
  },
  groupBy: groupBy,
  search: null,
  selectedTenant: null,
};

const mutations = {
  SET_DATA_TABLE_OPTIONS(state, payload) {
    state.dataTableOptions = payload;
  },

  SET_GROUP_BY(state, payload) {
    state.groupBy = payload;
  },

  SET_SEARCH(state, payload) {
    state.search = payload;
  },

  SET_SELECTED_TENANT(state, payload) {
    state.selectedTenant = payload;
  },
};

const actions = {
  setDataTableOptions(context, payload) {
    context.commit("SET_DATA_TABLE_OPTIONS", payload);
  },

  setGroupBy(context, payload) {
    context.commit("SET_GROUP_BY", payload);
    if (payload == true || payload == false) {
      localStorage.setItem("groupBy", JSON.stringify(payload));
    } else {
      localStorage.removeItem("groupBy");
    }
  },

  setSearch(context, payload) {
    context.commit("SET_SEARCH", payload);
  },

  setSelectedTenant(context, payload) {
    context.commit("SET_SELECTED_TENANT", payload);
  },
};

const getters = {
  getDataTableOptions(state) {
    return state.dataTableOptions;
  },

  getGroupBy(state) {
    return state.groupBy;
  },

  getSearch(state) {
    return state.search;
  },

  getSelectedTenant(state) {
    return state.selectedTenant;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
